<template>
  <div class="no-scroll">
    <div class="bg"></div>
    <div class="tltle">404</div>
    <div class="sub-title">this page is got lose</div>
    <router-link class="btn no-select" to="/">BACK TO HOME</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {}
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
.no-scroll {
  background: url("../assets/404bg.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .tltle {
    // margin-top: 322rem;
    width: 283rem;
    height: 222rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 160rem;
    line-height: 160rem;
    color: #f93086;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .sub-title {
    margin-top: -40rem;
    width: 283rem;
    height: 78rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 28rem;
    color: #ffffff;
    line-height: 78rem;
    font-weight: 700;
  }
  .btn {
    margin-top: 44rem;
    font-family: "GenSenMaruGothicTW-Bold";
    font-size: 20rem;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    width: 240rem;
    height: 60rem;
    background: #f93086;
    border-radius: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>